import { Link } from "react-router-dom";

import "./ModelsBlock.scss";

export const ModelsBlock = ({
                              children,
                              className,
                              items,
                              withImages,
                              header = "Выберите модель устройства",
                              ...props
                            }) => {
  return (
    <div className="models-block">
      <h2>{header}</h2>
      <div className="models-block-items">
        {items.sort(
          // sort by _created
          (a, b) => new Date(b._created) - new Date(a._created)
        ).map(({ slug, name, IconComponent, image }, index) => withImages ? (
          <Link key={index} className="models-block-item-link models-block-item-link-with-image" to={slug}>
            {image?.path &&
              <img src={`https://api.service-fma.ru/storage/uploads${image?.path}?w=150&h=150&f=jpg&q=80`}
                   alt={name} />}
            <div className="models-block-item-title">{name}</div>
          </Link>
        ) : (
          <Link key={index} className="models-block-item-link" to={slug}>
            <div className="models-block-item-title">{name}</div>
          </Link>
        ))}
      </div>
    </div>
  )
    ;
};
