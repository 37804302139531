import "./TextBanner.scss"
export const TextBanner = () => {
  return (
    <div className="text-banner">
      <h3>Ремонтируем больше 10 лет</h3>
      <p>Выполняем ремонт мобильных телефонов и компьютерной техники в Москве. Основное направление ремонта: телефоны,
        ноутбуки, планшеты и умные часы. За все время деятельности на рынке услуг с сентября 2010 года,
        отремонтировали десятки тысяч устройств и зарекомендовали себя как надежного партнера в сфере предоставления
        услуг ремонта гаджетов. Услуги сервисного центра направлены на максимальное решение задач наших клиентов, на
        высоком профессиональном уровне.</p>
      <h3>Ремонт любой сложности</h3>
      <ul>
        <li className="first">Модульный ремонт: восстановление после механических повреждений;</li>
        <li>Компонентный ремонт: восстановление после попадания жидкости;</li>
        <li className="last">Последствия не квалифицированного ремонта и многое другое.&nbsp;</li>
      </ul>
      <h3>Наши преимущества</h3>
      <ul>
        <li className="first">Предоставляем бесплатную и срочную диагностику телефонов;</li>
        <li>Выполняем ремонт в день обращения;</li>
        <li>Даем гарантию на все виды работ и запчасти;</li>
        <li>Предлагаем удобные способы оплаты: наличные или банковская карта;</li>
        <li>Ремонтируем рядом с метро Студенческая;</li>
        <li className="last">Работаем с физическими и юридическими лицами. Выдаем полный пакет документов на
          обслуживание в сервисном центре.&nbsp;</li>
      </ul>
      <p>Звоните и приходите в сервисный центр! Будем рады вас видеть и готовы помощь в решении любой задачи,
        связанной с ремонтом телефона или ноутбука.</p>
    </div>
  )
}