const API_KEY = 'API-2891b53dcc4ef0ec9b4a627447af766bc4ffc778';

export const getAllBrands = async () => {
  const response = await fetch('https://api.service-fma.ru/api/content/items/Brand', {
    headers: {
      'Content-Type': 'application/json',
      'api-key': API_KEY,
    },
  });

  console.log('getAllBrands: ', response);

  return response.json();
};

const getBrandBySlug = async (slug) => {
  const filterObject = {
    slug,
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/item/Brand?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  console.log('getBrandBySlug: ', response);

  return response.json();
};

export const getModelsByBrand = async (brand, deviceType) => {
  const brandData = await getBrandBySlug(brand);
  const deviceData = await getDeviceBySlug(deviceType);
  console.log('brandData: ', brandData);
  console.log('deviceData: ', deviceData);

  const filterObject = {
    brand: brandData._id,
    device: deviceData._id,
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/items/Model?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  return response.json();
};

export const getModelsByBrandAndCategory = async (brand, deviceType, category) => {
  const brandData = await getBrandBySlug(brand);
  const deviceData = await getDeviceBySlug(deviceType);
  console.log('brandData: ', brandData);
  console.log('deviceData: ', deviceData);

  const filterObject = {
    brand: brandData._id,
    device: deviceData._id,
    category: category === 'macbook-pro' ? 'MacBook Pro' : 'MacBook Air',
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/items/Model?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  return response.json();
};

export const getModelBySlug = async (slug) => {
  const filterObject = {
    slug,
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/item/Model?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  console.log('getModelBySlug: ', response);

  return response.json();
};

export const getServicesByModelId = async (id) => {
  const filterObject = {
    model: id,
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/items/service?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  console.log('getServicesByModelId: ', response);

  return response.json();
};

export const getDeviceBySlug = async (slug) => {
  const filterObject = {
    slug,
  };

  const filterString = encodeURIComponent(JSON.stringify(filterObject));

  const response = await fetch(
    `https://api.service-fma.ru/api/content/item/Device?filter=${filterString}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'api-key': API_KEY,
      },
    },
  );

  console.log('getDevicesBySlug: ', response);

  return response.json();
};
