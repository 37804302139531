import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import './Category.scss';
import MainBanner from '../../components/MainBanner';
import phoneImg from '../../assets/images/phone.png';
import laptopImg from '../../assets/images/laptop.png';
import tabletImg from '../../assets/images/tablet.png';
import watchImg from '../../assets/images/watch.png';
import RepairProcess from '../../components/RepairProcess';
import ModelsBlock from '../../components/ModelsBlock';
import { brandsByDeviceType } from '../../constants/brands';
import { getModelsByBrandAndCategory } from '../../utils/api';

const settingsByDeviceType = (brandSlug, brandName) => {
  const data = {
    phones: {
      header: `РЕМОНТ ТЕЛЕФОНОВ ${brandName.toUpperCase()}`,
      img: phoneImg,
      desc: `Сломался телефон ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    laptops: {
      header: `РЕМОНТ НОУТБУКОВ ${brandName.toUpperCase()}`,
      img: laptopImg,
      desc: `Сломался ноутбук ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    tablets: {
      header: `РЕМОНТ ПЛАНШЕТОВ ${brandName.toUpperCase()}`,
      img: tabletImg,
      desc: `Сломался планшет ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    watches: {
      header: `РЕМОНТ ЧАСОВ ${brandName.toUpperCase()}`,
      img: watchImg,
      desc: `Сломались часы ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
  };

  return data[brandSlug];
};

export const Category = () => {
  const { brand, device, category } = useParams();
  const [models, setModels] = useState([]);

  const brandData = brandsByDeviceType[device].brands.reduce((obj, item) => {
    obj[item.slug] = item;
    return obj;
  }, {})[brand];

  const { header, img, desc } = settingsByDeviceType(device, brandData.title);

  useEffect(() => {
    getModelsByBrandAndCategory(brand, device, category).then((data) => {
      setModels(data);
    });
  }, []);

  return (
    <div className="section">
      <div className="content-section">
        <MainBanner header={header} text={desc} img={img} />
      </div>
      <div className='content-section'>
        <ModelsBlock items={models} withImages={device === 'phones' && brand === 'apple'} />
      </div>
      <RepairProcess />
    </div>
  );


};
