

import paymentIcon from "./assets/payment.svg";
import garantyIcon from "./assets/garanty.svg";
import timeIcon from "./assets/speed.svg";
import parkingIcon from "./assets/parking.svg";

import './Benefits.scss'

const data = [
  {
    icon: timeIcon,
    title: 'Срочный ремонт',
    text: 'Срочная диагностика и ремонт устройства в вашем присутствии'
  },
  {
    icon: garantyIcon,
    title: 'Гарантия и качество',
    text: 'Гарантия на все виды работ и установленные запчасти'
  },
  {
    icon: paymentIcon,
    title: 'Способы оплаты',
    text: 'Наличный и безналичный расчет после завершения ремонта'
  },
  {
    icon: parkingIcon,
    title: 'Метро и городская парковка',
    text: '2 минуты от метро'
  },
]
export const Benefits = () => {
  return (
    <section className='benefits'>
      <div className='benefits-wrapper'>
        {data.map((item, index) => (
          <div className='benefits-item' key={index}>
            <div className='benefits-item-icon'>
              <img src={item.icon} alt='icon' />
            </div>
            <div>
              <div className='benefits-item-title'>{item.title}</div>
              <div className='benefits-item-text' dangerouslySetInnerHTML={{ __html: item.text }} />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}