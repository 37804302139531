import { Routes, Route } from 'react-router-dom';

import Layout from './components/Layout';
import OrderForm from './components/OrderForm';
import Home from './pages/Home';
import Phones from './pages/Phones';
import Tablets from './pages/Tablets';
import Laptops from './pages/Laptops';
import Watches from './pages/Watches';
import NoMatch from './pages/NoMatch';
import Brand from './pages/Brand';
import Devices from './pages/Devices';
import Model from './pages/Model';
import Category from './pages/Category';
import Contacts from './pages/Contacts';
import Feedbacks from './pages/Feedbacks';
import { allBrands } from './constants/brands';
import './App.scss';

function App() {
  return (
    <div className='App'>
      <OrderForm />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/contacts' element={<Contacts />} />
          <Route path='/feedbacks' element={<Feedbacks />} />
          <Route path='/phones' element={<Phones />} />
          <Route path='/tablets' element={<Tablets />} />
          <Route path='/laptops' element={<Laptops />} />
          <Route path='/watches' element={<Watches />} />
          <Route path='/:device/:brand' element={<Brand />} />
          <Route path='/:device/:brand/category/:category' element={<Category />} />
           <Route path='/:device/:brand/category/:category/:model' element={<Model />} />
          <Route path='/:device/:brand/:model' element={<Model />} />
          {allBrands.map(({ slug }) => (
            <Route key={slug} path={`/${slug}`} element={<Devices brand={slug} />} />
          ))}
          <Route path='*' element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
