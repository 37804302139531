import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import './Model.scss';
import MainBanner from '../../components/MainBanner';
import phoneImg from '../../assets/images/phone.png';
import laptopImg from '../../assets/images/laptop.png';
import tabletImg from '../../assets/images/tablet.png';
import watchImg from '../../assets/images/watch.png';
import PriceTable from '../../components/PriceTable';
import RepairProcess from '../../components/RepairProcess';
import { getModelBySlug, getServicesByModelId } from '../../utils/api';

const settingsByDeviceType = (device, brand, model) => {
  const data = {
    phones: {
      header: `РЕМОНТ ${brand.toUpperCase()} ${model.toUpperCase()}`,
      img: phoneImg,
      desc: `Сломался телефон ${brand.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    laptops: {
      header: `РЕМОНТ ${brand.toUpperCase()} ${model.toUpperCase()}`,
      img: laptopImg,
      desc: `Сломался ноутбук ${brand.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    tablets: {
      header: `РЕМОНТ ${brand.toUpperCase()} ${model.toUpperCase()}`,
      img: tabletImg,
      desc: `Сломался планшет ${brand.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    watches: {
      header: `РЕМОНТ ${brand.toUpperCase()} ${model.toUpperCase()}`,
      img: watchImg,
      desc: `Сломались часы ${brand.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
  };

  return data[device];
};

export const Model = () => {
  const { brand, model, device } = useParams();
  const [services, setServices] = useState([]);
  const [modelInfo, setModelInfo] = useState(null);

  useEffect(() => {
    (async () => {
      const modelInfo = await getModelBySlug(model);
      setModelInfo(modelInfo);

      if (modelInfo) {
        const services = await getServicesByModelId(modelInfo._id);

        setServices(services);
      }
    })();
  }, []);

  const { header, img } = settingsByDeviceType(device, brand, modelInfo?.name || '');

  return (
    <div className='section'>
      <div className='content-section'>
        <MainBanner header={header} text={modelInfo?.description} img={img} />
      </div>
      <div className='content-section'>
        <PriceTable categoryTitle={modelInfo?.name} services={services} />
      </div>
      <RepairProcess />
    </div>
  );
};
