import { Link , useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';

import './Brand.scss';
import MainBanner from '../../components/MainBanner';
import phoneImg from '../../assets/images/phone.png';
import laptopImg from '../../assets/images/laptop.png';
import tabletImg from '../../assets/images/tablet.png';
import watchImg from '../../assets/images/watch.png';
import MacBookAirImg from '../../assets/images/MacBook-Air.jpg';
import MacBookProImg from '../../assets/images/MacBook-Pro.jpg';
// import PriceTable from '../../components/PriceTable';
import RepairProcess from '../../components/RepairProcess';
import ModelsBlock from '../../components/ModelsBlock';
import { brandsByDeviceType } from '../../constants/brands';
import { getModelsByBrand } from '../../utils/api';

const settingsByDeviceType = (brandSlug, brandName) => {
  const data = {
    phones: {
      header: `РЕМОНТ ТЕЛЕФОНОВ ${brandName.toUpperCase()}`,
      img: phoneImg,
      desc: `Сломался телефон ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    laptops: {
      header: `РЕМОНТ НОУТБУКОВ ${brandName.toUpperCase()}`,
      img: laptopImg,
      desc: `Сломался ноутбук ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    tablets: {
      header: `РЕМОНТ ПЛАНШЕТОВ ${brandName.toUpperCase()}`,
      img: tabletImg,
      desc: `Сломался планшет ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
    watches: {
      header: `РЕМОНТ ЧАСОВ ${brandName.toUpperCase()}`,
      img: watchImg,
      desc: `Сломались часы ${brandName.toUpperCase()}? Звоните! <br />
            Диагностика бесплатно. Ремонт без очереди <br />
            Гарантия на работу и запчасти. Любые способы оплаты.`,
    },
  };

  return data[brandSlug];
};

export const Brand = () => {
  const { brand, device } = useParams();
  const [models, setModels] = useState([]);

  const brandData = brandsByDeviceType[device].brands.reduce((obj, item) => {
    obj[item.slug] = item;
    return obj;
  }, {})[brand];

  console.log('brandData', brandData);

  const { header, img, desc } = settingsByDeviceType(device, brandData.title);

  useEffect(() => {
    getModelsByBrand(brand, device).then((data) => {
      console.log('getModelsByBrand:', data);
      setModels(data);
    });
  }, []);

  if (device === 'laptops' && brand === 'apple') {
    return (
      <div className='section'>
        <div className='content-section'>
          <MainBanner header={header} text={desc} img={img} />
        </div>
        <div className="content-section">
          <h2 className="categories-title">Выберите модель MacBook, чтобы узнать стоимость ремонта</h2>
          <div className="categories">
            <Link to="category/macbook-pro" className="category-link">
              <div>
                <img src={MacBookProImg} border={0} alt="MacBook Pro" />
                <div>MacBook Pro</div>
              </div>
            </Link>
            <Link to="category/macbook-air" className="category-link">
              <div>
                <img src={MacBookAirImg} border={0} alt="MacBook Air" />
                <div>MacBook Air</div>
              </div>
            </Link>
          </div>
        </div>
        {/* <div className='content-section'> */}
        {/*  <PriceTable items={brandsByDeviceType[deviceType].brands} /> */}
        {/* </div> */}
        <RepairProcess />
      </div>
    );
  }

  return (
    <div className="section">
      <div className="content-section">
        <MainBanner header={header} text={desc} img={img} />
      </div>
      <div className='content-section'>
        <ModelsBlock items={models} withImages={device === 'phones' && brand === 'apple'} />
      </div>
      {/* <div className='content-section'> */}
      {/*  <PriceTable items={brandsByDeviceType[deviceType].brands} /> */}
      {/* </div> */}
      <RepairProcess />
    </div>
  );


};
